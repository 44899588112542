// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/components/WeekView.js

import React from 'react';
import {
    format,
    addHours,
    startOfWeek,
    addDays,
    eachHourOfInterval,
    isToday,
    isWeekend,
    isSameHour,
    setHours,
    setMinutes
} from 'date-fns';
import { Clock, User } from 'lucide-react';
import './WeekView.css';

const WeekView = ({ currentDate, visits, onVisitClick }) => {
    // Create week days array
    const weekStart = startOfWeek(currentDate);
    const weekDays = [...Array(7)].map((_, i) => addDays(weekStart, i));

    // Create time slots (8 AM to 6 PM)
    const dayStart = setHours(setMinutes(weekStart, 0), 8);
    const dayEnd = setHours(setMinutes(weekStart, 0), 18);
    const timeSlots = eachHourOfInterval({ start: dayStart, end: dayEnd });

    const getVisitsForDayAndHour = (day, hour) => {
        return visits.filter(visit => {
            const visitDate = new Date(visit.scheduledDateTime);
            return isSameHour(visitDate, hour) &&
                format(visitDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd');
        });
    };

    const calculateVisitPosition = (visit) => {
        const visitStart = new Date(visit.scheduledDateTime);
        const minutes = visitStart.getMinutes();
        const top = (minutes / 60) * 4; // 4rem is the height of one hour
        const height = (visit.durationMinutes / 60) * 4;
        return { top: `${top}rem`, height: `${height}rem` };
    };

    const renderVisit = (visit) => {
        const { top, height } = calculateVisitPosition(visit);
        const startTime = format(new Date(visit.scheduledDateTime), 'h:mm a');

        return (
            <div
                key={visit.visitId}
                className="week-visit"
                style={{ top, height }}
                onClick={() => onVisitClick(visit)}
            >
                <div className="visit-header">
                    <span className="visit-time">
                        <Clock size={12} className="visit-icon" />
                        {startTime}
                    </span>
                    <span className={`visit-status status-${visit.status.toLowerCase()}`}>
                        {visit.status}
                    </span>
                </div>
                <span className="visit-patient">
                    <User size={12} className="visit-icon" />
                    {visit.patientName}
                </span>
            </div>
        );
    };

    return (
        <div className="week-view">
            <div className="week-header">
                <div className="header-placeholder"></div>
                {weekDays.map(day => (
                    <div key={day.toString()} className="day-header">
                        <span className="day-name">{format(day, 'EEEE')}</span>
                        <span className="day-date">{format(day, 'MMM d')}</span>
                    </div>
                ))}
            </div>
            <div className="week-content">
                <div className="time-column">
                    {timeSlots.map(time => (
                        <div key={time.toString()} className="time-slot">
                            {format(time, 'h a')}
                        </div>
                    ))}
                </div>
                {weekDays.map(day => (
                    <div
                        key={day.toString()}
                        className={`day-column${isToday(day) ? ' today' : ''}${isWeekend(day) ? ' weekend' : ''}`}
                    >
                        <div className="time-grid">
                            {timeSlots.map(time => (
                                <React.Fragment key={time.toString()}>
                                    <div className="grid-line" />
                                    {getVisitsForDayAndHour(day, time).map(visit => renderVisit(visit))}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WeekView;