// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/components/CalendarView.js

import React from 'react';
import MonthView from './MonthView';
import WeekView from './WeekView';
import DayView from './DayView';
import './CalendarView.css';

const CalendarView = ({ viewType, currentDate, visits, onVisitClick }) => {
    const renderView = () => {
        switch (viewType) {
            case 'month':
                return (
                    <MonthView
                        currentDate={currentDate}
                        visits={visits}
                        onVisitClick={onVisitClick}
                    />
                );
            case 'week':
                return (
                    <WeekView
                        currentDate={currentDate}
                        visits={visits}
                        onVisitClick={onVisitClick}
                    />
                );
            case 'day':
                return (
                    <DayView
                        currentDate={currentDate}
                        visits={visits}
                        onVisitClick={onVisitClick}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="calendar-view">
            {renderView()}
        </div>
    );
};

export default CalendarView;