// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/components/DayView.js

import React from 'react';
import {
    format, setHours, setMinutes, eachHourOfInterval,
    eachMinuteOfInterval, isSameHour, addMinutes
} from 'date-fns';
import './DayView.css';

const DayView = ({ currentDate, visits, onVisitClick }) => {
    // Create time slots for the day (8 AM to 6 PM)
    const dayStart = setHours(setMinutes(currentDate, 0), 8);
    const dayEnd = setHours(setMinutes(currentDate, 0), 18);
    const hourSlots = eachHourOfInterval({ start: dayStart, end: dayEnd });
    const minuteSlots = eachMinuteOfInterval(
        { start: dayStart, end: dayEnd },
        { step: 15 }
    );

    const getVisitsForHour = (hour) => {
        return visits.filter(visit => {
            const visitDate = new Date(visit.scheduledDateTime);
            return isSameHour(visitDate, hour);
        });
    };

    const calculateVisitPosition = (visit) => {
        const visitStart = new Date(visit.scheduledDateTime);
        const minutesSinceStart = (visitStart.getHours() - 8) * 60 + visitStart.getMinutes();
        const top = (minutesSinceStart / 15) * 1; // 1rem per 15 minutes
        const height = visit.durationMinutes / 15; // Convert duration to height units

        return { top, height };
    };

    const renderTimeSlot = (slot) => (
        <div key={slot.toString()} className="day-time-slot">
            <div className="time-label">{format(slot, 'h:mm a')}</div>
            <div className="time-line" />
        </div>
    );

    const renderVisit = (visit) => {
        const { top, height } = calculateVisitPosition(visit);
        const startTime = format(new Date(visit.scheduledDateTime), 'h:mm a');
        const endTime = format(
            addMinutes(new Date(visit.scheduledDateTime), visit.durationMinutes),
            'h:mm a'
        );

        return (
            <div
                key={visit.visitId}
                className={`day-view-visit status-${visit.status.toLowerCase()}`}
                style={{
                    top: `${top}rem`,
                    height: `${height}rem`
                }}
                onClick={() => onVisitClick(visit)}
            >
                <div className="visit-header">
                    <span className="visit-time">{startTime} - {endTime}</span>
                    <span className="visit-duration">{visit.durationMinutes}min</span>
                </div>
                <div className="visit-patient">{visit.patientName}</div>
                <div className="visit-template">{visit.templateName || 'No template'}</div>
                <div className={`visit-status status-${visit.status.toLowerCase()}`}>
                    {visit.status}
                </div>
            </div>
        );
    };

    return (
        <div className="day-view">
            <div className="day-header">
                <h2 className="day-title">{format(currentDate, 'EEEE, MMMM d, yyyy')}</h2>
            </div>
            <div className="day-content">
                <div className="time-column">
                    {minuteSlots.map(slot => renderTimeSlot(slot))}
                </div>
                <div className="events-column">
                    {hourSlots.map(hour => {
                        const hourVisits = getVisitsForHour(hour);
                        return hourVisits.map(visit => renderVisit(visit));
                    })}
                </div>
            </div>
        </div>
    );
};

export default DayView;