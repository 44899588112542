// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/Scheduler.js

import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Calendar as CalendarIcon } from 'lucide-react';
import useFetchWithAuth from '../../../utils/useFetchWithAuth';
import { useMessage } from '../../../components/Message/MessageProvider';
import CalendarView from './components/CalendarView';
import VisitDialog from './components/VisitForm';
import AddVisitDialog from './components/AddVisitDialog';
import './Scheduler.css';

const Scheduler = () => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [viewType, setViewType] = useState('month'); // month, week, day
    const [visits, setVisits] = useState([]);
    const [selectedVisit, setSelectedVisit] = useState(null);
    const [showAddVisit, setShowAddVisit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchVisits();
    }, [currentDate, viewType]);

    const fetchVisits = async () => {
        try {
            setIsLoading(true);
            const startDate = getViewStartDate();
            const endDate = getViewEndDate();

            /* const response = await fetchWithAuth(
                `/api/visit/range?companyId=${companyId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
            ); 

            setVisits(response.$values || []); */
        } catch (error) {
            showMessage('error', 'Error fetching visits. Please try again.');
            console.error('Error fetching visits:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getViewStartDate = () => {
        const date = new Date(currentDate);
        switch (viewType) {
            case 'month':
                date.setDate(1);
                break;
            case 'week':
                const day = date.getDay();
                date.setDate(date.getDate() - day);
                break;
            case 'day':
                // Start of current day
                date.setHours(0, 0, 0, 0);
                break;
        }
        return date;
    };

    const getViewEndDate = () => {
        const date = new Date(currentDate);
        switch (viewType) {
            case 'month':
                date.setMonth(date.getMonth() + 1);
                date.setDate(0);
                break;
            case 'week':
                const day = date.getDay();
                date.setDate(date.getDate() + (6 - day));
                break;
            case 'day':
                date.setHours(23, 59, 59, 999);
                break;
        }
        return date;
    };

    const handlePrevious = () => {
        const date = new Date(currentDate);
        switch (viewType) {
            case 'month':
                date.setMonth(date.getMonth() - 1);
                break;
            case 'week':
                date.setDate(date.getDate() - 7);
                break;
            case 'day':
                date.setDate(date.getDate() - 1);
                break;
        }
        setCurrentDate(date);
    };

    const handleNext = () => {
        const date = new Date(currentDate);
        switch (viewType) {
            case 'month':
                date.setMonth(date.getMonth() + 1);
                break;
            case 'week':
                date.setDate(date.getDate() + 7);
                break;
            case 'day':
                date.setDate(date.getDate() + 1);
                break;
        }
        setCurrentDate(date);
    };

    const handleToday = () => {
        setCurrentDate(new Date());
    };

    const handleVisitClick = (visit) => {
        setSelectedVisit(visit);
    };

    const handleVisitClose = () => {
        setSelectedVisit(null);
    };

    const handleAddVisitClick = () => {
        setShowAddVisit(true);
    };

    const handleAddVisitClose = () => {
        setShowAddVisit(false);
    };

    const handleVisitCreated = async (visit) => {
        await fetchVisits();
        setShowAddVisit(false);
        showMessage('success', 'Visit scheduled successfully');
    };

    const handleVisitUpdated = async (visit) => {
        await fetchVisits();
        setSelectedVisit(null);
        showMessage('success', 'Visit updated successfully');
    };

    const handleVisitDeleted = async () => {
        await fetchVisits();
        setSelectedVisit(null);
        showMessage('success', 'Visit deleted successfully');
    };

    const getViewTitle = () => {
        const options = { month: 'long', year: 'numeric' };
        switch (viewType) {
            case 'month':
                return currentDate.toLocaleDateString('en-US', options);
            case 'week':
                const weekStart = new Date(currentDate);
                weekStart.setDate(weekStart.getDate() - weekStart.getDay());
                const weekEnd = new Date(weekStart);
                weekEnd.setDate(weekEnd.getDate() + 6);
                return `${weekStart.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} - ${weekEnd.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`;
            case 'day':
                return currentDate.toLocaleDateString('en-US', { ...options, day: 'numeric' });
            default:
                return '';
        }
    };

    return (
        <div className="scheduler-container">
            <div className="scheduler-header">
                <div className="scheduler-controls">
                    <div className="view-controls">
                        <button
                            className={`view-button ${viewType === 'month' ? 'active' : ''}`}
                            onClick={() => setViewType('month')}
                        >
                            Month
                        </button>
                        <button
                            className={`view-button ${viewType === 'week' ? 'active' : ''}`}
                            onClick={() => setViewType('week')}
                        >
                            Week
                        </button>
                        <button
                            className={`view-button ${viewType === 'day' ? 'active' : ''}`}
                            onClick={() => setViewType('day')}
                        >
                            Day
                        </button>
                    </div>
                    <div className="navigation-controls">
                        <button className="nav-button" onClick={handleToday}>
                            Today
                        </button>
                        <button className="nav-button" onClick={handlePrevious}>
                            <ChevronLeft className="icon" />
                        </button>
                        <button className="nav-button" onClick={handleNext}>
                            <ChevronRight className="icon" />
                        </button>
                        <span className="current-view-title">{getViewTitle()}</span>
                    </div>
                    <button className="add-visit-button" onClick={handleAddVisitClick}>
                        New Visit
                    </button>
                </div>
            </div>

            <div className="scheduler-content">
                {isLoading ? (
                    <div className="loading-spinner">Loading...</div>
                ) : (
                    <CalendarView
                        viewType={viewType}
                        currentDate={currentDate}
                        visits={visits}
                        onVisitClick={handleVisitClick}
                    />
                )}
            </div>

            {selectedVisit && (
                <VisitDialog
                    visit={selectedVisit}
                    onClose={handleVisitClose}
                    onUpdate={handleVisitUpdated}
                    onDelete={handleVisitDeleted}
                />
            )}

            {showAddVisit && (
                <AddVisitDialog
                    onClose={handleAddVisitClose}
                    onSave={handleVisitCreated}
                />
            )}
        </div>
    );
};

export default Scheduler;